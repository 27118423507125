import { render, staticRenderFns } from "./ExampleComponent.vue?vue&type=template&id=13bf442d&"
import script from "./ExampleComponent.vue?vue&type=script&lang=js&"
export * from "./ExampleComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nexus/sites/slmagic-backup/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13bf442d')) {
      api.createRecord('13bf442d', component.options)
    } else {
      api.reload('13bf442d', component.options)
    }
    module.hot.accept("./ExampleComponent.vue?vue&type=template&id=13bf442d&", function () {
      api.rerender('13bf442d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/ExampleComponent.vue"
export default component.exports